import React, { Fragment } from "react";

import { MainHeader, Logo, ContainerItems } from "../../assets/styles/Header.styled";

import LogoHeader from "./../../assets/images/LogoHeader.png";

const Header = (props) => {
  return (
    <>
      <MainHeader>
        <ContainerItems jc="flex-start">
          <Logo src={LogoHeader} width="50%" alt="logo" />
        </ContainerItems>

      </MainHeader>
    </>
  );
};

export default Header;
