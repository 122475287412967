import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom"; 
import Layout from "./Layout/Layout";
import LpAfore from './LpAfore/LpAfore';
import Simulador from './simulador/simulador';
import BusquedaAfore from './BusquedaAfore/BusquedaAfore';
import PerfiladorCuenta from './Perfilador/PerfiladorCuenta';
import PerfiladorContacto from './Perfilador/PerfiladorContacto';
import ScrollTop from "./scrollTop"; 
function App() {
  return (
    <BrowserRouter>
     <ScrollTop>
      <Layout>
        <Routes onUpdate={()=>window.location.reload()} >
       
          <Route exact path='/' element={<LpAfore/>} />
          <Route exact path='/simulador' element={<Simulador/>} />
          <Route exact path='/busqueda-afore' element={<BusquedaAfore/>} />
          <Route exact path='/perfilador-cuenta' element={<PerfiladorCuenta/>} />
          <Route exact path='/perfilador-contacto' element={<PerfiladorContacto/>} />
        </Routes>
       </Layout>
       </ScrollTop>
    </BrowserRouter>
  );
}

export default App;
