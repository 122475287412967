import React from "react";
import {
  ContainerFooterLinks,
  FooterA,
} from "../../assets/styles/Footer.styled";
import SocialMedia from "../common/SocialMediaShare";

const FooterLinks = (props) => {
  return (
    <div style={{ display: "flex", flexDirection: "column " }}>
      <div style={{ width: "100%", margin: "30px 0px 0px 250px" }}>
        <SocialMedia />
      </div>
      <ContainerFooterLinks>
        <FooterA target="_blank" href="https://www.gob.mx/consar">
          CONSAR
        </FooterA>
        <FooterA target="_blank" href="https://amafore.org/">
          AMAFORE
        </FooterA>
        <FooterA target="_blank" href="https://www.gipsstandards.org/">
          GIPS
        </FooterA>
        <FooterA target="_blank" href="https://www.gob.mx/cnbv">
          CNBV
        </FooterA>
        <FooterA target="_blank" href="https://www.amib.com.mx/">
          AMIB
        </FooterA>
        <FooterA
          target="_blank"
          href="https://www.morningstar.com/company?cid=RED_EOL0001"
        >
          Morningstar
        </FooterA>
      </ContainerFooterLinks>
    </div>
  );
};

export default FooterLinks;
