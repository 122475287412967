import React, { useState, useEffect } from "react";
import {
  ContainerSection,
  ItemSection,
  Title,
  ContainerItems,
  ItemWrapper,
  ItemRow,
  Label,
  Inpt,
  Button,
} from "../../assets/styles/StylesGeneric.styled";
import "./../../assets/styles/StylesGeneric.css";
import ComponenteInput from "../../components/common/Input/Input";
import { Formulario } from "../../components/common/Input/elementos/Formularios";
import { useNavigate } from "react-router-dom";
import lambdas from "../../config/lambdas/lambdas";
// import ChatInput from '../components/ChatInput';
const FormularioBusqueda = ({}) => {
  const [nombre, setNombre] = useState({ campo: "", valido: null });
  const [apellidoPaterno, setApellidoPaterno] = useState({
    campo: "",
    valido: null,
  });
  const [apellidoMaterno, setApellidoMaterno] = useState({
    campo: "",
    valido: null,
  });
  const [telefono, setTelefono] = useState({ campo: "", valido: null });
  const [correo, setCorreo] = useState({ campo: "", valido: null });
  const [curp, setCurp] = useState({ campo: "", valido: null });

  const expresiones = {
    // usuario: /^[a-zA-Z0-9_-]{4,16}$/, // Letras, numeros, guion y guion_bajo
    nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
    // password: /^.{4,12}$/, // 4 a 12 digitos.
    correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    telefono: /^\d{7,14}$/, // 7 a 14 numeros.
    curp: /^.{18,18}$/,
  };

  const buscarAfore = () => {
    if (
      nombre.campo !== "" &&
      nombre.valido === "true" &&
      apellidoPaterno.campo !== "" &&
      apellidoPaterno.valido === "true" &&
      apellidoMaterno.campo !== "" &&
      apellidoMaterno.valido === "true" &&
      telefono.campo !== "" &&
      telefono.valido === "true" &&
      correo.campo !== "" &&
      correo.valido === "true" &&
      curp.campo !== "" &&
      curp.valido === "true"
    ) {
      const data = {
        nombre: nombre.campo,
        apellido_paterno: apellidoPaterno.campo,
        apellido_materno: apellidoMaterno.campo,
        celular: telefono.campo,
        email: correo.campo,
        curp: curp.campo,
      };
      lambdas
        .buscarAfore(data)
        .then((response) => {
          console.log("response", response);
          if (!response.data.Error) {
            const { existe_cliente } = response.data.Message;
            if (existe_cliente) {
              console.log("Existe");
            } else {
              console.log("No Existe");
            }
            console.log("response buscar afore", response.data.Message);
          }
        })
        .catch((error) => {
          console.log(error.data);
        });
      // navigate("/")
    }
  };

  const navigate = useNavigate();
  return (
    <>
      <ContainerSection mh="100vh">
        <ContainerItems
          mw="100%"
          m="auto"
          pad1300="35px 30px 35px 0"
          pad="35px 0"
        >
          <ItemWrapper w="38rem" wrap="wrap">
            <ItemSection w="100%">
              <Title>
                {" "}
                Ingresa tus datos para saber si tu Afore se encuentra con
                Principal.{" "}
              </Title>
            </ItemSection>
            <ItemSection w="100%" wrap="wrap" pad="0 80px">
              <Formulario style={{ gap: "3rem" }} gtc="1fr">
                <div>
                  <ComponenteInput
                    estado={nombre}
                    cambiarEstado={setNombre}
                    tipo="text"
                    placeholder=""
                    name="nombre"
                    padLabel="10px 0px 0px"
                    hLabel="20px"
                    label="Nombre"
                    expresionRegular={expresiones.nombre}
                    leyendaError="Nombre inválido"
                  />

                  <ComponenteInput
                    estado={apellidoPaterno}
                    cambiarEstado={setApellidoPaterno}
                    tipo="text"
                    placeholder=""
                    label="Apellido Paterno"
                    name="apellido-paterno"
                    padLabel="10px 0px 0px"
                    hLabel="20px"
                    leyendaError="Apellido inválido"
                    expresionRegular={expresiones.nombre}
                  />

                  <ComponenteInput
                    id="formatted-numberformat-input"
                    estado={apellidoMaterno}
                    cambiarEstado={setApellidoMaterno}
                    tipo="text"
                    placeholder=""
                    label="Apellido Materno"
                    padLabel="10px 0px 0px"
                    hLabel="20px"
                    name="apelido-materno"
                    expresionRegular={expresiones.nombre}
                    leyendaError="Apellido inválido"
                  />

                  <ComponenteInput
                    estado={telefono}
                    cambiarEstado={setTelefono}
                    tipo="text"
                    placeholder=""
                    name="nombre"
                    padLabel="10px 0px 0px"
                    hLabel="20px"
                    label="Teléfono Celular"
                    expresionRegular={expresiones.telefono}
                    leyendaError="Teléfono celular inválido"
                  />

                  <ComponenteInput
                    estado={correo}
                    cambiarEstado={setCorreo}
                    tipo="text"
                    placeholder=""
                    padLabel="10px 0px 0px"
                    hLabel="20px"
                    name="nombre"
                    label="Correo electrónico"
                    expresionRegular={expresiones.correo}
                    leyendaError="Correo inválido"
                  />

                  <ComponenteInput
                    estado={curp}
                    cambiarEstado={setCurp}
                    tipo="text"
                    placeholder=""
                    name="nombre"
                    padLabel="10px 0px 0px"
                    hLabel="20px"
                    label="CURP"
                    expresionRegular={expresiones.curp}
                    leyendaError="CURP invalida"
                    inputProps={{ style: { textTransform: "lowercase" } }}
                  />
                </div>
              </Formulario>
            </ItemSection>
            <ItemSection w="100%" flexD=" " jc="center" m="10px 0 ">
              <Button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  buscarAfore();
                }}
                hMob="48px"
                mMob="0"
                als="center"
              >
                Enviar
              </Button>
            </ItemSection>
          </ItemWrapper>
        </ContainerItems>
      </ContainerSection>
    </>
  );
};

export default FormularioBusqueda;
