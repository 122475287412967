import axios from "axios";

const ApiBaseUrl = 'https://aitpzz8ooc.execute-api.us-east-1.amazonaws.com/'

const Api = axios.create({
  baseURL: ApiBaseUrl,
  headers: {
      'Content-Type': 'application/json'
  }
});

export default Api;