import styled from "styled-components";

export const ButtonBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 414px;
  height: 308px;
  background: #f0fbfe;
  padding: ${(props) => props.p || "25px 30px"};
  border-radius: 10px;
  @media (max-width: 800px) {
    margin: auto;
  }
`;

export const SubContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const TitleBox = styled.p`
  width: 100%;
  height: 38.93px;
  left: 1038px;
  top: 139.94px;
  text-align: center;
  /* Body text bold 3 */

  font-family: "eliotprobold";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: ${(props) => props.lh || "20px"};

  /* Gray/ Gris 1 */

  color: #004c97;
`;

const GrupoInput = styled.div`
  position: relative;
  z-index: 0;
`;

const ContenedorBotonCentrado = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: span 2;

  @media (max-width: 800px) {
    grid-column: span 1;
  }
`;

const Button = styled.button`
  height: 45px;
  line-height: 45px;
  width: ${(props) => (props.w ? props.w : "85%")};
  background: #f0fbfe;
  color: #0076cf;
  font-weight: bold;
  border: 1px solid #0076cf;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.1s ease all;
  margin-bottom: 20px;
  filter: ${(props) => props.filter || "none"};
  &:hover {
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 1);
    background: #0076cf;
    color: #fff;
  }
`;
export const Paragraph16 = styled.div`
  font-family: "eliotpro";
  font-style: normal;
  font-weight: ${(props) => props.fw || "400"};
  font-size: 16px;
  line-height: 15px;
  color: #333333;
  margin: ${(props) => props.mbMob || "20px 0px 30px 0px"};
  @media (max-width: 800px) {
    width: ${(props) => props.wMob || " "};
    height: ${(props) => props.hMob || " "};
    margin: ${(props) => props.mbMob || "30px 0px"};
    font-weight: ${(props) => props.fwMob || "400 "};
  }
  @media (max-width: 320px) {
  }
`;

export { GrupoInput, ContenedorBotonCentrado, Button };
