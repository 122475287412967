import React from "react";
import {
  ContainerSection,
  ItemSection,
  MainTitle,
  Paragraph24,
  ContainerItems,
  ItemWrapper,
  ItemRow,
  ItemCol,
  ContactInfoBox,
  InnerInfoBox,
} from "../../assets/styles/StylesGeneric.styled";
import "./../../assets/styles/StylesGeneric.css";
import ButtonBox from "../components/ButtonBox";

const FrontSection = () => {
  return (
    <>
      <ContainerSection className="mainSection" mh="70vh">
        <ContainerItems m="auto" pad1300="35px 30px 35px 0" pad="35px 0">
          <ItemWrapper
            w="50%"
            pad="0 0 0 12%"
            pad1250="0 0 0 9%"
            pad1000="0 0 0 3%"
          >
            <ItemSection m320Mob="60px 0px 0px 5%">
              <MainTitle> Cambia tu Afore a Principal </MainTitle>
              <Paragraph24 hMob="140px" mb320Mob="40px" mb="30px" mw="460px">
                Ahorra para tu retiro. ¡Nunca la Afore había estado tan cerca de
                ti! Sin <br /> necesidad de salir de casa, ahora es posible
                llevar a cabo tu proceso de <br /> cambio con tus amigos de
                Principal.{" "}
              </Paragraph24>
            </ItemSection>
          </ItemWrapper>
          <ItemWrapper w="50%" dis800="none">
            <ItemSection disMob="none" id="contact">
              <ButtonBox />
            </ItemSection>
          </ItemWrapper>
        </ContainerItems>
        <ContainerItems mw="100%">
          <ItemRow>
            <ItemCol>
              <ContactInfoBox bg="rgba(8, 28, 45, 0.7)">
                <InnerInfoBox>Contacta a un asesor</InnerInfoBox>
                <InnerInfoBox>555236521</InnerInfoBox>
              </ContactInfoBox>
            </ItemCol>
            <ItemCol>
              <ContactInfoBox bg="rgba(8, 28, 45, 0.7)">
                <InnerInfoBox>Whatsapp</InnerInfoBox>
                <InnerInfoBox>555236521</InnerInfoBox>
              </ContactInfoBox>
            </ItemCol>
          </ItemRow>
        </ContainerItems>
      </ContainerSection>
    </>
  );
};

export default FrontSection;
