import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../../assets/styles/flipcard.css";
import FlipCard from "../components/FlipCard";
import card1 from "./../../assets/icons/card1Img.png";
import card2 from "./../../assets/icons/card2Img.svg";
import card3 from "./../../assets/icons/card3Img.svg";
import plusIcon from "./../../assets/icons/plusIcon.svg";
import { Button } from "../../assets/styles/StylesGeneric.styled";
const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100%",
    backgroundColor: "#F0FBFE",
    height: "48rem",
  },
  section: {
    maxWidth: "61.5rem",
    margin: "auto",
    padding: "110px 30px 0 30px",
    backgroundColor: "#F0FBFE",

    minHeight: "600px",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    height: "50px",
    alignContent: "center",
    alignItems: "center",
    fontWeight: "400",
    fontSize: "40px",
    fontFamily: "eliotprolight",
    color: "#333333",
    "@media (max-width: 768px)": {
      fontWeight: "400",
      fontSize: "21px",
    },
    "@media (max-width: 500px)": {
      fontWeight: "400",
      fontSize: "18px",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    paddingTop: "30px",
  },
  listContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "15px",
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    paddingTop: "30px",
  },
  rowWidth: {
    width: "100%",
  },
  images: {
    width: "100%",
  },
  hideOnMovil: {
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  listRow: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    "@media (max-width: 768px)": {
      paddingBottom: "25px",
    },
  },
  item: {
    marginLeft: "15px",
  },
  itemTitle: {
    fontWeight: "700",
    fontSize: "18px",
    color: "#002855",
  },
  itemContent: {
    fontWeight: "400",
    fontSize: "18px",
  },
  justCont: {
    justifyContent: "center",
    margin: "50px 0",
  },
}));

const cards = [
  {
    id: "1",
    variant: "click",
    frontText: "Mejor asesoría",
    backTitle: "Mejor asesoría",
    backText1:
      "Tendrás la asesoría indicada para ti, confianza y seguridad en nuestras manos.",
    backText2:
      " Somos expertos en la materia, descubre nuestra asesoría y sé todo un experto en Afores.",
    src: card1,
    icon: plusIcon,
  },
  {
    id: "2",
    variant: "click",
    frontText: "Confianza",
    backTitle: "Confianza",
    backText1: (
      <span>
        Tenemos experiencia en otras partes del mundo,
        <br />
        buscamos quitar esa preocupación monetaria el día que te retires.
        <br />
        Estamos en constante progreso y actualización de estrategias para apoyar
        tu tranquilidad al retirarte.{" "}
      </span>
    ),
    backText2: "",
    back: "Back",
    src: card2,
    icon: plusIcon,
  },
  {
    id: "3",
    variant: "click",
    frontText: "Mayores rendimientos",
    backTitle: "Mayores rendimientos",
    backText1: (
      <span>
        En Principal, buscamos el mejor rendimiento para ti, actualizamos esos
        rendimientos mes con mes para ser competitivos en el mercado.
        <br /> <br />
        Recuerda, rendimientos pasados no te aseguran rendimientos futuros.
      </span>
    ),
    backText2: "",
    src: card3,
    icon: plusIcon,
  },
];

export default function SectionTwo() {
  const classes = useStyles();

  return (
    <div className={classes.outerWrapper}>
      <div className={classes.section}>
        <Row>
          <div className={classes.title}>
            <span>¿Por qué cambiarte a Principal Afore?</span>
          </div>
        </Row>
        <Row className={classes.justCont}>
          {cards.map((card) => (
            <Col md={4} sm={12}>
              <FlipCard key={card.id} card={card} />
            </Col>
          ))}
        </Row>
        <Row className={classes.justCont}>
          <Button
            type="button"
            onClick={() => window.location.replace("/#contact")}
            hMob="48px"
            mMob="0"
            als="center"
            w="220px"
          >
            Contactar a un asesor
          </Button>
        </Row>
      </div>
    </div>
  );
}
