import React from 'react';
import {
  ContainerSection,
  CustomParagraph,
  ContainerItems,
  ItemRow,
  Button
} from '../../assets/styles/StylesGeneric.styled';
import './../../assets/styles/StylesGeneric.css';
import { useNavigate } from "react-router-dom";

const SectionThree = ({ }) => {
  const navigate = useNavigate();
  return (
    <>
      <ContainerSection className='sectionThree' mh="40vh" fdir=" ">
        <ContainerItems mw='100%' fw="wrap">
          <ItemRow m="10px 0">
            <CustomParagraph clr="#00EFE8" fontw="700">Saber si mi Afore esta en Principal</CustomParagraph>
          </ItemRow >
          <ItemRow m="10px 0">
            <CustomParagraph ff="eliotprolight">Aqui puedes saber si tu Afore se encuentra con nosotros.</CustomParagraph>
          </ItemRow>
          <ItemRow m="10px 0">
            <Button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                navigate('/busqueda-afore')
              }}
              bgck="#0061a000"
              bder="1px solid #fff"
              ff="eliotprolight"
              hMob="48px"
              mMob="0"
              als="center"
              HoverbackgroundColor="#0061a000"

            >Buscar mi afore</Button>
          </ItemRow>
        </ContainerItems>
      </ContainerSection>
    </>
  )
}

export default SectionThree;