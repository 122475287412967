import React from "react";
import {
  ButtonBoxWrapper,
  TitleBox,
  SubContainer,
  Paragraph16,
  ContenedorBotonCentrado,
  Button,
} from "../../assets/styles/ButtonBox.styled";
import { useNavigate } from "react-router-dom";
const ButtonBox = () => {
  const navigate = useNavigate();
  return (
    <ButtonBoxWrapper>
      <SubContainer>
        <TitleBox lh="2rem">
          ¡Cámbiate a Principal, realiza tu proceso digital!
        </TitleBox>
        <Paragraph16 style={{ marginTop: "30px" }} fw="200">
          ¿Has realizado algún trámite en lo últimos 6 meses con tu Afore?
        </Paragraph16>
        <ContenedorBotonCentrado>
          <Button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              navigate("/perfilador-cuenta");
            }}
            w="75%"
            m="0px 0px 0px 120px"
            hMob="48px"
            mMob="0"
            filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
            als="center"
          >
            Si
          </Button>
          <Button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              navigate("/perfilador-contacto");
            }}
            w="75%"
            m="0px 0px 0px 120px"
            hMob="48px"
            filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
            mMob="0"
            als="center"
          >
            No
          </Button>
        </ContenedorBotonCentrado>
      </SubContainer>
    </ButtonBoxWrapper>
  );
};

export default ButtonBox;
