import React from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";

export function FormatNumber({ number }) {
  return (
    <span>
      {new Intl.NumberFormat("ES-MX", {
        style: "currency",
        currency: "MXN",
      }).format(number)}
    </span>
  );
}

export function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={true}
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
