
import React, {Fragment} from 'react';
import FourSection from './sections/SectionFour'
import SectionTwo from "./sections/SectionTwo";
import SectionThree from "./sections/SectionThree";
import FrontSection from './sections/FrontSection'



const LpFondos = () => {
  return (

  <Fragment>
       <FrontSection />
       <SectionTwo />
       <SectionThree />
       <FourSection/>
  </Fragment> 

  )
}

export default LpFondos
