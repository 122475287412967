/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

// import PropTypes from 'prop-types'
import {
  ContainerSection,
  TitlePerfiladorAfore,
  ContainerItems,
  Button,
  ItemCol,
  ItemRow,
  CustomParagraph,
  Title,
  ItemWrapper,
  ItemSection,
} from "../assets/styles/StylesGeneric.styled";
import Contacto from "../components/common/Contacto";
import ComponenteInput from "../components/common/Input/Input";
import { Formulario } from "../components/common/Input/elementos/Formularios";
import ScreenIcon from "../assets/icons/ScreenIcon.svg";
import DataIcon from "../assets/icons/DataIcon.svg";
import VerifyIcon from "../assets/icons/VerifyIcon.svg";
import VideoIcon from "../assets/icons/VideoIcon.svg";
import PhotoIcon from "../assets/icons/PhotoIcon.svg";
import SignIcon from "../assets/icons/SignIcon.svg";
import googlePlay from "../assets/images/GooglePLay.png";
import appStore from "../assets/images/AppStore.png";

function PerfiladorCuenta() {
  const [nombre, setNombre] = useState({ campo: "", valido: null });
  const [apellidoPaterno, setApellidoPaterno] = useState({
    campo: "",
    valido: null,
  });
  const [apellidoMaterno, setApellidoMaterno] = useState({
    campo: "",
    valido: null,
  });
  const [telefono, setTelefono] = useState({ campo: "", valido: null });
  const [correo, setCorreo] = useState({ campo: "", valido: null });
  const [curp, setCurp] = useState({ campo: "", valido: null });

  const expresiones = {
    // usuario: /^[a-zA-Z0-9_-]{4,16}$/, // Letras, numeros, guion y guion_bajo
    nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
    // password: /^.{4,12}$/, // 4 a 12 digitos.
    correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    telefono: /^\d{7,14}$/, // 7 a 14 numeros.
    curp: /^.{18,18}$/,
  };

  return (
    <>
      <ItemWrapper dis="block" bgcolor="#F0FBFE">
        <ContainerSection bgck="#F0FBFE" w="51rem" m="auto" pad="60px 0">
          <img src={ScreenIcon} alt="" />
          <TitlePerfiladorAfore m="3rem 0" fontf="eliotprolight">
            ¡Excelente! <br />
            Es bastante probable que cuentes con tu expediente digital
          </TitlePerfiladorAfore>
          <ItemRow>
            <CustomParagraph clr="#333333" fs="18px" tal="center">
              Un expediente digital es un registro del trabajador en el sistema
              de ahorro para el retiro, sirve para realizar de forma más ágil
              los trámites relacionados con tu cuenta de Afore.
            </CustomParagraph>
            <CustomParagraph clr="#333333" fs="18px" tal="center" m="20px 0">
              Crea tu cuenta para iniciar tu proceso de cambio de Afore a
              Principal:
            </CustomParagraph>
          </ItemRow>

          <ContainerItems ai="start" pad="0rem 0rem 4rem">
            <ItemSection w="100%" mw="450px" wrap="wrap">
              <Formulario style={{ gap: "3rem" }} gtc="1fr">
                <div>
                  <ComponenteInput
                    estado={nombre}
                    cambiarEstado={setNombre}
                    tipo="text"
                    placeholder=""
                    name="nombre"
                    label="Nombre"
                    expresionRegular={expresiones.nombre}
                    leyendaError="Nombre inválido"
                  />

                  <ComponenteInput
                    estado={apellidoPaterno}
                    cambiarEstado={setApellidoPaterno}
                    tipo="text"
                    placeholder=""
                    label="Apellido Paterno"
                    name="apellido-paterno"
                    leyendaError="Apellido inválido"
                    expresionRegular={expresiones.nombre}
                  />

                  <ComponenteInput
                    id="formatted-numberformat-input"
                    estado={apellidoMaterno}
                    cambiarEstado={setApellidoMaterno}
                    tipo="text"
                    placeholder=""
                    label="Apellido Materno"
                    name="apelido-materno"
                    expresionRegular={expresiones.nombre}
                    leyendaError="Apellido inválido"
                  />

                  <ComponenteInput
                    estado={telefono}
                    cambiarEstado={setTelefono}
                    tipo="text"
                    placeholder=""
                    name="nombre"
                    label="Teléfono Celular"
                    expresionRegular={expresiones.telefono}
                    leyendaError="Teléfono celular inválido"
                  />

                  <ComponenteInput
                    estado={correo}
                    cambiarEstado={setCorreo}
                    tipo="text"
                    placeholder=""
                    name="nombre"
                    label="Correo electrónico"
                    expresionRegular={expresiones.correo}
                    leyendaError="Correo inválido"
                  />

                  <ComponenteInput
                    estado={curp}
                    cambiarEstado={setCurp}
                    tipo="text"
                    placeholder=""
                    name="nombre"
                    label="CURP"
                    expresionRegular={expresiones.curp}
                    leyendaError="CURP invalida"
                    inputProps={{ style: { textTransform: "lowercase" } }}
                  />
                </div>
              </Formulario>
            </ItemSection>
          </ContainerItems>

          <Button mb="1rem 0rem auto">Crear cuenta</Button>
        </ContainerSection>
        <ItemRow>
          <Title fontSize="30px">
            Pasos para realizar tu traspaso de Afore
          </Title>
        </ItemRow>

        <ItemRow w="42rem" m="auto">
          <ItemRow m="13px 0">
            <ItemCol w="10%" dis="flex">
              <img src={DataIcon} alt="" />
            </ItemCol>
            <ItemCol w="80%" pad="0 0 0 10px ">
              <CustomParagraph clr="#333333" fs="20px">
                Llenar datos faltantes en la aplicación de Principal.
              </CustomParagraph>
            </ItemCol>
          </ItemRow>

          <ItemRow m="13px 0">
            <ItemCol w="10%" dis="flex">
              <img src={VerifyIcon} alt="" />
            </ItemCol>
            <ItemCol w="80%" pad="0 0 0 10px ">
              <CustomParagraph clr="#333333" fs="20px">
                Verificarás tu cuenta con Principal.
              </CustomParagraph>
            </ItemCol>
          </ItemRow>

          <ItemRow m="13px 0">
            <ItemCol w="10%" dis="flex">
              <img src={VideoIcon} alt="" />
            </ItemCol>
            <ItemCol w="80%" pad="0 0 0 10px ">
              <CustomParagraph clr="#333333" fs="20px">
                Video muestra siguientes pasos.
              </CustomParagraph>
            </ItemCol>
          </ItemRow>

          <ItemRow m="13px 0">
            <ItemCol w="10%" dis="flex">
              <img src={PhotoIcon} alt="" />
            </ItemCol>
            <ItemCol w="80%" pad="0 0 0 10px ">
              <CustomParagraph clr="#333333" fs="20px">
                Capturar fotografía de acuerdo a lo indicado.
              </CustomParagraph>
            </ItemCol>
          </ItemRow>

          <ItemRow m="13px 0">
            <ItemCol w="10%" dis="flex">
              <img src={SignIcon} alt="" />
            </ItemCol>
            <ItemCol w="80%" pad="0 0 0 10px ">
              <CustomParagraph clr="#333333" fs="20px">
                Firma de contrato de tu traspaso.
              </CustomParagraph>
            </ItemCol>
          </ItemRow>
        </ItemRow>

        <ContainerItems
          ai="start"
          pad="3.5rem 2.5rem 4rem"
          mw="42.5rem"
          m="auto"
        >
          <ItemRow>
            <ItemCol w="49%" jc="flex-start" wrap=" " dis="flex" m=" ">
              <img src={googlePlay} alt="" />
            </ItemCol>
            <ItemCol w="49%" jc="flex-end" wrap=" " dis="flex" m=" ">
              <img src={appStore} alt="" />
            </ItemCol>
          </ItemRow>
        </ContainerItems>
      </ItemWrapper>
      <Contacto />
    </>
  );
}

// LpAfore.propTypes = {}

export default PerfiladorCuenta;
