import React from 'react'
import { ContainerItems} from '../../assets/styles/StylesGeneric.styled'

import Moneda from './../../assets/images/gif/Moneda.gif'






export default function AnimationBox() {
  return (
    <ContainerItems w="500px" h="370px" bgck="#E3F5FD" br="16px">
      
      <img src={Moneda} alt='Animation'/>
    
        
    </ContainerItems>
  )
}
