import React, { useState } from "react";

import { CardOne, CardTwo, CardThree } from "../components/CardsCarousel";
import {
  ContainerItems,
  ContainerSection,
} from "../../assets/styles/StylesGeneric.styled";
import { styled } from "@mui/material/styles";
import { Button, Step, StepLabel, Stepper } from "@mui/material";
import buttonIzq from "../../assets/icons/button.svg";
import buttonDer from "../../assets/icons/button_d.svg";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
// import PropTypes from 'prop-types'
const steps = ["", "", ""];

const CustomedConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 15,
    left: "calc(-50% + 15px)",
    right: "calc(50% + 15px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#ffffff",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#ffffff",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  alignItems: "center",
  ...(ownerState.active && {
    color: "#000000",
    width: 32,
    height: 32,
    borderRadius: "50%",
    border: "1px solid #fff",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#000000",
    width: 32,
    height: 32,
    borderRadius: "50%",
    border: "1px solid #fff",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff !important",
  },
  "& .QontoStepIcon-circle": {
    width: 32,
    height: 32,
    borderRadius: "50%",
    border: "1px solid #fff",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className, index } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="QontoStepIcon-completedIcon">{index + 1}</div>
      ) : (
        <div className="QontoStepIcon-circle">{index + 1}</div>
      )}
    </QontoStepIconRoot>
  );
}

const SectionFour = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    if (activeStep < 2) setActiveStep((currenStep) => currenStep + 1);
  };

  const handleBack = () => {
    setActiveStep((currenStep) => currenStep - 1);
  };
  return (
    <ContainerSection mh="58rem" className="Gradiante-fourSectionLp">
      {activeStep === 0 && <CardOne />}
      {activeStep === 1 && <CardTwo />}
      {activeStep === 2 && <CardThree />}

      {/* <InputTest/> */}
      <ContainerItems gap="1rem" m="auto">
        {activeStep !== 0 ? (
          <Button onClick={handleBack}>
            <img src={buttonIzq} alt="" />
          </Button>
        ) : (
          <div></div>
        )}
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          style={{ width: "74%" }}
          connector={<CustomedConnector />}
        >
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={(props) => (
                    <QontoStepIcon index={index} {...props} />
                  )}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep !== 2 ? (
          <Button
            onClick={handleNext}
            disabled={!(activeStep !== steps.length - 1)}
          >
            <img src={buttonDer} alt="" />
          </Button>
        ) : (
          <div></div>
        )}
      </ContainerItems>
    </ContainerSection>
  );
};

// SectionFour.propTypes = {}

export default SectionFour;
