import React, { Fragment } from "react";
import FooterBuroSection from "./FooterBuroSection";
import FooterNav from "./FooterNav";

const Footer = () => {
  return (
    <Fragment>
      <FooterNav />
      <FooterBuroSection />
    </Fragment>
  );
};

export default Footer;
