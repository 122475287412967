import styled from 'styled-components';

export const MainHeader = styled.div`
 width: 100%;
 display: flex;
 justify-content: center;
 height:60px ;
 background: #0091DA;
 margin-bottom:0px;
`;

export const Logo = styled.img`
  width: ${(props) => props.w || '150px'};
  height: ${(props) => props.h || '47px'}; 
  margin: ${(props) => props.m || ''};
  @media (max-width: 700px) {
  margin: ${(props) => props.mMob || '10px 0px 0px 3%'};
  }
  @media (max-width: 425px) {
  margin: ${(props) => props.mMob425 || '10px 0px 0px 9%'};
  }
  @media (max-width: 390px) {
  margin: ${(props) => props.mMob390 || '10px 0px 0px 4%'};
  }
  @media (max-width: 375px) {
  margin: ${(props) => props.mMob375 || '10px 0px 0px 6%'};
  }
  @media (max-width: 320px) {
  margin: ${(props) => props.mMob320 || '10px 0px 0px .5%'};
  }
`;

export const ContainerItems = styled.div`
display:${(props) => props.dis|| ' flex '} ;
flex-direction:${(props) => props.dis|| ' '} ; 
width:${(props) => props.w || '100%'}   ;
height:${(props) => props.h || ' '}  ;
margin:${(props) => props.m || ' '};
padding:${(props) => props.pad || ' '};
margin-left:${(props) => props.left || ' '};
background:${(props) => props.bgck || ''} ;
opacity:${(props) => props.op || ' '} ; 
align-items:${(props) => props.ai || 'center'};
justify-content:${(props) => props.jc || 'center'};
max-width: 64rem;
@media (max-width: 1150px) {
  
  padding:${(props) => props.pad1300 || '0 0 0 2%'};

  }
@media (max-width: 800px) {
  
padding:${(props) => props.pad800 || 'unset'};
font-size:30px
}
    
`;


export const Image = styled.img`
  max-width: ${(props) => props.maxwidth || '100%'};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || ' '};
  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.bottom || ''};
  margin-right: ${(props) => props.marginRight || ''};
  margin-left: ${(props) => props.marginLeft || ''};
  position: ${(props) => props.position || 'initial'};
  left: ${(props) => props.left || ''};
  top: ${(props) => props.top || ''};
  cursor: ${(props) => props.cursor || ' '};
  display: ${(props) => props.displayWeb || ' '};
  border: ${(props) => props.border || ' '};
  z-index: ${(props) => props.zindex || ''};
  transform: ${(props) => props.transform || ''};
  transform-origin: ${(props) => props.to || ''};
  max-height: ${(props) => props.mheight || ' '};
  @media (max-width: 800px) {
    margin-bottom: ${(props) => props.bottomMObile || ''};
    width: ${(props) => props.mwidth || props.width || '100%'};
    left: ${(props) => props.mleft || ''};
    top: ${(props) => props.mtop || ''};
    margin-right: ${(props) => props.marginRightM || ''};
    margin-top: ${(props) => props.marginTopM || ''};
    margin-left: ${(props) => props.marginLeftM || ''};
  }
  @media (max-width: 700px) {
    display: ${(props) => props.displayMobile || ' '};
    height: ${(props) => props.heightMobile || ' '};
  }
  @media (max-width: 460px) {
    width: ${(props) => props.mwidth || props.width || '100%'};
    height: ${(props) => props.heightMobile || ' '};
    display: ${(props) => props.displayMobile || ' '};
    margin-top: ${(props) => props.mtMob || ' '};
    margin-bottom: ${(props) => props.mbMob || ' '};
    margin-left: ${(props) => props.mlMob || ' '};
  }
`;










