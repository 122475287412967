/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { NumberFormatCustom } from "../utils/formatNumber";
// import PropTypes from 'prop-types'
import {
  ContainerSection,
  TitleSimuladorAfore,
  ContainerItems,
  Button,
  CardAnimationTwo,
  FormContainer,
  Inp,
  Labels,
  InfoSimulador,
  InfoSimuladorMoney,
  CustomParagraph,
} from "./../assets/styles/StylesGeneric.styled";
import Contacto from "../components/common/Contacto";
import Moneda from "../assets/images/gif/Moneda.gif";
import Monedas from "../assets/images/gif/2-Varias-monedas.gif";
import Billetes from "../assets/images/gif/3-Billetes.gif";
import Bolsa from "../assets/images/gif/4-Bolsa.gif";
import ComponenteInput from "../components/common/Input/Input";
import { Formulario } from "../components/common/Input/elementos/Formularios";

function Simulador({ price = "$200,000" }) {
  const [ahorroAfore, setAhorroAfore] = useState({
    campo: 0,
    valido: "",
  });
  const [edad, setEdad] = useState({
    campo: 0,
    valido: "",
  });
  const [edadRetiro, setEdadRetiro] = useState({
    campo: 0,
    valido: "",
  });
  const [ahorroMensual, setAhorroMensual] = useState({
    campo: 0,
    valido: "",
  });
  const [ingresoMensual, setIngresoMensual] = useState({
    campo: 0,
    valido: "",
  });

  const [actualConfig, setActualConfig] = useState({
    text: "",
    bg: "",
    gif: "",
  });

  const selectOptions = (value) => {
    switch (value) {
      case 1:
        return {
          gif: Bolsa,
          text: "¡Perfecto! Justo es lo que necesitas ahorrar para lograr tus objetivos en tu vejez, sigue así.",
          bg: "linear-gradient(71.21deg, #00A29B 29.17%, #00827D 100%)",
        };
      case 2:
        return {
          gif: Monedas,
          text: "Sería un gran ahorro, pero podría ser mejor, recuerda que todo lo que logres ahorrar tú yo del futuro te lo agradecerá.",
          bg: "linear-gradient(71.21deg, #E2A700 30.73%, #FFC82E 100%)",
        };
      case 3:
        return {
          gif: Moneda,
          text: "Es muy poco tu ahorro, te recomendamos mejorarlo, esto sirve para tu tranquilidad en la vejez.",
          bg: "linear-gradient(71.21deg, #EA965A 29.17%, #F76800 100%)",
        };
      default:
        return {
          gif: Billetes,
          text: "",
          bg: "#E3F5FD",
        };
    }
  };

  useEffect(() => {
    const option = selectOptions(0);
    setActualConfig(option);
  }, []);

  const validarLongitud = (estado, cambiarEstado, min, max) => {
    if (estado.campo.length < min || estado.campo.length > max) {
      cambiarEstado({ ...estado, valido: "false" });
    } else {
      cambiarEstado({ ...estado, valido: "true" });
    }
  };
  const validarEdad = (estado, cambiarEstado, min, max) => {
    if (estado.campo > min && estado.campo < max) {
      cambiarEstado({ ...estado, valido: "true" });
    } else {
      cambiarEstado({ ...estado, valido: "false" });
    }
  };

  const validarDineroMin = (estado, cambiarEstado, min, max) => {
    console.log(parseInt(estado.campo));
    if (parseInt(estado.campo) >= min && estado.campo.length < max) {
      cambiarEstado({ ...estado, valido: "true" });
    } else {
      cambiarEstado({ ...estado, valido: "false" });
    }
  };

  return (
    <>
      <ContainerSection h="1029px" bgck={actualConfig.bg}>
        <TitleSimuladorAfore mt="3rem">
          Descubre cuanto podrías ganar en el tiempo que tú <br /> decidas
        </TitleSimuladorAfore>
        <ContainerItems ai="start" pad="3.5rem 0rem 4rem">
          <Formulario style={{ gap: "3rem" }}>
            <div>
              <ComponenteInput
                cambiarEstado={setAhorroAfore}
                estado={ahorroAfore}
                placeholder=""
                type="money"
                label={
                  <span>
                    ¿A cuánto crees que asciende tu ahorro en tu Afore? <br />
                    (Aproximado)
                  </span>
                }
                name="ahorro_afore"
                leyendaError={
                  ahorroAfore.campo.length > 9
                    ? "Solo se permiten menos de 9 caracteres"
                    : "Campo obligatorio"
                }
                funcion={() =>
                  validarLongitud(ahorroAfore, setAhorroAfore, 1, 9)
                }
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
              <ComponenteInput
                cambiarEstado={setEdad}
                estado={edad}
                type="number"
                label="¿Cuál es tu edad?"
                placeholder=""
                name="edad"
                funcion={() => validarEdad(edad, setEdad, 18, 60)}
                leyendaError="Requiere una edad mayor a 18 años y menor a 60 años"
              />
              <ComponenteInput
                cambiarEstado={setEdadRetiro}
                estado={edadRetiro}
                type="number"
                placeholder=""
                label="¿A qué edad te gustaría retirarte?"
                funcion={() => validarEdad(edadRetiro, setEdadRetiro, 60, 99)}
                leyendaError="Requiere una edad mayor a 60 años"
                name="edad-retiro"
              />
              <ComponenteInput
                cambiarEstado={setAhorroMensual}
                estado={ahorroMensual}
                placeholder=""
                label="¿Cuánto puedes ahorrar mensual?"
                leyendaError="Requiere un mínimo de $500"
                funcion={() =>
                  validarDineroMin(ahorroMensual, setAhorroMensual, 500, 9)
                }
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                name="ahorro-mensual"
              />
              <ComponenteInput
                cambiarEstado={setIngresoMensual}
                estado={ingresoMensual}
                placeholder=""
                label="¿Cuál es tu ingreso mensualmente?"
                funcion={() =>
                  validarDineroMin(ingresoMensual, setIngresoMensual, 1000, 9)
                }
                leyendaError="Requiere un mínimo de $1,000"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                name="ingreso-mensual"
              />

              <InfoSimulador>Podrias tener en tu afore</InfoSimulador>
              <InfoSimuladorMoney>{price}</InfoSimuladorMoney>
            </div>
            <CardAnimationTwo>
              <img src={actualConfig.gif} alt="" width="170px" />
              <CustomParagraph fs="24px" clr="#000" tal="center">
                {actualConfig.text}
              </CustomParagraph>
            </CardAnimationTwo>
          </Formulario>
        </ContainerItems>

        <Button mb="1rem 0rem auto">Ir a la aplicación</Button>
      </ContainerSection>
      <Contacto />
    </>
  );
}

// LpAfore.propTypes = {}

export default Simulador;
