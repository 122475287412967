import React, { Fragment } from "react";
import Facebook from "../../assets/images/SectionShare/FaceBook.png";
import YuoTube from "../../assets/images/SectionShare/Youtube.png";
import Twittwer from "../../assets/images/SectionShare/Twitter.png";
import Linkedin from "../../assets/images/SectionShare/Linkedin.png";
import { Image } from "../../assets/styles/StylesGeneric.styled";
import styled from 'styled-components';

const FooterViralidad = styled.div`
display: flex;
width: ${(props) => props.width || '100%'};
margin-left: ${(props) => props.left || ' '};
@media (max-width: 769px) {
  width: 100%;
}
`;






const ShareSection = () => {
  return (
    <Fragment>
      <FooterViralidad width="91%" left="auto">
        <a target="_blank" href="https://www.facebook.com/principal.mexico.oficial/" rel="noreferrer">
          <Image
            src={Facebook}
            marginRight="18px"
            className="img-fluid cursor-p"
            width="44.06px"
            marginTop="2px"
            bottom="30px"
            displayWeb="block"
            bottomMObile="block"
            // displayMobile="none"
          />
        </a>
        <a target="_blank" href="https://twitter.com/Principal_mx" rel="noreferrer">
          <Image
            src={Twittwer}
            marginRight="18px"
            className="img-fluid cursor-p"
            width="44.06px"
            marginTop="2px"
            bottom="30px"
            displayWeb="block"
            bottomMObile="block"
            displayMobile="block"
          />
        </a>
        <a target="_blank" href="https://www.linkedin.com/company/principalfinancialgroup/" rel="noreferrer">
          <Image
            src={Linkedin}
            marginRight="18px"
            className="img-fluid cursor-p"
            width="44.06px"
            marginTop="2px"
            bottom="30px"
            displayWeb="block"
            bottomMObile="block"
            displayMobile="block"
          />
        </a>
        <a target="_blank" href="https://www.youtube.com/channel/UC891ECUCkClKOeSoKGB1kSQ" rel="noreferrer">
          <Image
            src={YuoTube}
            marginRight="18px"
            className="img-fluid cursor-p"
            width="44.06px"
            marginTop="2px"
            bottom="30px"
            displayWeb="block"
            bottomMObile="block"
            displayMobile="block"
          />
        </a>
       
      </FooterViralidad>
    </Fragment>
  );
};

export default ShareSection;
