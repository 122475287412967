/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import {
  FooterContainerItemsNav,
  FooterContainerNav,
  FooterVerticals,
  FooterDiv1,
  FooterDiv2,
  FooterDiv3,
  FooterVerticalsA,
  ContainerLogo,
  FooterSubTitle,
} from "../../assets/styles/Footer.styled";
import LogoFooter from "./../../assets/images/FooterLogo.png";

const FooterNav = () => {
  return (
    <Fragment>
      <FooterContainerNav>
        <ContainerLogo>
          <img width="220px" height="70px" src={LogoFooter} alt="LogoFooter" />
        </ContainerLogo>

        <FooterContainerItemsNav>
          <FooterDiv3 pl="5%">
            <FooterSubTitle size="20px">Conoce a Principal</FooterSubTitle>
            <FooterVerticals
              size="14px"
              className="roboto"
              target="_blank"
              href="https://www.principal.com.mx/conocenos/acerca-de-principal"
            >
              Acerca de Principal
            </FooterVerticals>

            <FooterVerticals
              size="14px"
              className="roboto"
              target="_blank"
              href="https://www.principal.com.mx/conocenos/talento-principal"
            >
              Buscamos tu talento
            </FooterVerticals>

            <FooterVerticals
              size="14px"
              className="roboto"
              target="_blank"
              href="https://www.principal.com.mx/conocenos/contacto"
            >
              Contacto
            </FooterVerticals>

            <FooterVerticals
              size="14px"
              className="roboto"
              target="_blank"
              href="https://www.principal.com.mx/mapa-de-sitio"
            >
              Mapa del sitio
            </FooterVerticals>
          </FooterDiv3>

          <FooterDiv3 pl="3%">
            <FooterSubTitle size="20px">Principal Institucional</FooterSubTitle>
            <FooterVerticals
              size="14px"
              className="roboto"
              target="_blank"
              href="https://institucional.principal.com.mx/"
            >
              Principal Institucional
            </FooterVerticals>
          </FooterDiv3>

          <FooterDiv3>
            <FooterSubTitle size="20px">Avisos legales</FooterSubTitle>

            <FooterVerticals
              size="14px"
              className="roboto"
              target="_blank"
              href="https://www.principal.com.mx/avisos/aviso-de-privacidad"
            >
              Aviso de privacidad
            </FooterVerticals>
            <FooterVerticals
              size="14px"
              className="roboto"
              target="_blank"
              href="https://www.principal.com.mx/avisos/afore"
            >
              Avisos Afore
            </FooterVerticals>
            <FooterVerticals
              size="14px"
              className="roboto"
              target="_blank"
              href="https://www.principal.com.mx/avisos/fondos-de-inversion"
            >
              Avisos Fondos de Inversion
            </FooterVerticals>

            <FooterVerticals
              size="14px"
              className="roboto"
              target="_blank"
              href="https://www.principal.com.mx/avisos/seguros"
            >
              Aviso Seguro
            </FooterVerticals>
          </FooterDiv3>

          <FooterDiv1 width="100%">
            <FooterVerticals
              className="roboto"
              // onClick={() => handleClickCoru('Soluciones')}
            ></FooterVerticals>

            <FooterVerticals
              className="roboto"
              // onClick={() => handleClickCoru('Tarjetadecrédito')}
            ></FooterVerticals>

            <FooterVerticals
              className="roboto"
              // onClick={() => handleClickCoru('Préstamospersonales')}
            ></FooterVerticals>
          </FooterDiv1>

          <FooterDiv2 width="100%">
            <FooterVerticals
              className="roboto"
              //  onClick={() => handleClickCoru('SegurodeAuto')}
            ></FooterVerticals>

            <hr
              style={{
                border: "  0.5px solid rgba(6, 29, 128, 0.24)",
                width: "70%",
                marginRight: "115px",
              }}
            />

            <FooterVerticals
              className="roboto"
              //  onClick={handleClickConocenos}
            ></FooterVerticals>

            <FooterVerticals
              className="roboto"
              // onClick={() => handleClickCoru('Blog')}
            ></FooterVerticals>
          </FooterDiv2>

          <FooterDiv2 mtop=" 20px" width="100%">
            <FooterVerticalsA
              target="_blanck"
              size="14px"
              line="160%"
              className="roboto"
              displayD="none"
              displayM="block"
            ></FooterVerticalsA>
          </FooterDiv2>
        </FooterContainerItemsNav>
      </FooterContainerNav>
    </Fragment>
  );
};

export default FooterNav;
