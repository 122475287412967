import React from "react";
import {
  ContainerSection,
  Button,
  ContainerItems,
  ItemWrapper,
  ItemRow,
  CustomParagraph,
} from "../../assets/styles/StylesGeneric.styled";
import "./../../assets/styles/StylesGeneric.css";
import { useNavigate } from "react-router-dom";
// import ChatInput from '../components/ChatInput';

const Contacto = () => {
  const navigate = useNavigate();
  return (
    <>
      <ContainerSection>
        <ContainerItems mw="100%" m="auto">
          <ItemWrapper
            className="callSection"
            w="50%"
            minh="45vh"
            jc="flex-end"
          >
            <ContainerItems mw="30rem" fw="wrap" alc="center">
              <ItemRow m="15px 0" pad="0 70px">
                <CustomParagraph fontw="700">
                  Llamar a un asesor
                </CustomParagraph>
              </ItemRow>
              <ItemRow m="15px 0">
                <CustomParagraph tal="center" fs="16px">
                  Puedes llamar a un asesor para resolver todas tus dudas.
                </CustomParagraph>
              </ItemRow>
              <ItemRow m="15px 0">
                <Button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/");
                  }}
                  bgck="#fff"
                  als="center"
                  clr="#0061A0"
                  HoverbackgroundColor="#fff"
                  Color="#0061A0"
                  
                >
                  01800245658
                </Button>
              </ItemRow>
            </ContainerItems>
          </ItemWrapper>
          <ItemWrapper
            className="whatsappSection"
            w="50%"
            dis800="none"
            minh="45vh"
            jc="flex-start"
          >
            <ContainerItems mw="34rem" fw="wrap" alc="center" pad="0 25px">
              <ItemRow m="15px 0" pad="0 70px" txal="center">
                <CustomParagraph fontw="700">
                  Contactar a un asesor por WhatsApp
                </CustomParagraph>
              </ItemRow>
              <ItemRow m="15px 0">
                <CustomParagraph tal="center" fs="16px">
                  También puedes contactar directamente a nuestro asesor dando
                  clic en el siguiente botón.
                </CustomParagraph>
              </ItemRow>
              <ItemRow m="15px 0">
                <Button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/");
                  }}
                  bgck="#fff"
                  als="center"
                  clr="#0061A0"
                  HoverbackgroundColor="#fff"
                  Color="#0061A0"
                  
                >
                  Enviar Whatsapp
                </Button>
              </ItemRow>
            </ContainerItems>
          </ItemWrapper>
        </ContainerItems>
      </ContainerSection>
    </>
  );
};

export default Contacto;
