import styled from "styled-components";
import TextField from "@material-ui/core/TextField";

const colores = {
  borde: "#0075FF",
  error: "#F81934",
  exito: "#0091DA",
};

export const LabelSlider = styled.div`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;

  position: absolute;
  width: 100px;
  height: 40px;
  left: 200px;
  top: 0px;

  /* Extras/White */

  background: #ffffff;
  border-radius: 20px;
`;

export const BoxStyle = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px;
  gap: 32px;

  position: absolute;
  width: 1016px;
  height: 480px;
  left: calc(50% - 1016px / 2);
  top: calc(50% - 480px / 2 - 0px);

  /* Primarios/Sky blue 2 */

  background: #e3f5fd;
  box-shadow: -8px 8px 25px rgba(0, 76, 151, 0.2);
  border-radius: 16px;

  /* Inside auto layout */

  flex: none;
  order: 3;
  flex-grow: 0;
  z-index: 3;
`;
export const BoxTitle = styled.div`
  width: 856px;
  height: 49px;

  /* Header 2 */

  font-family: "eliotpro";
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 49px;
  /* identical to box height */

  text-align: center;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const BoxDescription = styled.h2`
  width: 856px;
  height: 87px;

  /* Header 6 */

  font-family: "eliotpro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  /* Inside auto layout */

  flex: none;
  order: 4;
  align-self: stretch;
  flex-grow: 0;
`;

export const BoxModal = styled.div`
  display: ${(props) => props.dis || "flex"};
  flex-wrap: wrap;
  align-items: center;

  gap: 13px;

  position: absolute;
  width: ${(props) => props.w || "370px"};
  height: ${(props) => props.h || "433px"};
  left: calc(50% - 370px / 2);
  top: calc(50% - 433px / 2 - 0px);

  background: #ffffff;
  box-shadow: -8px 8px 25px rgba(0, 76, 151, 0.2);
  border-radius: 16px;
  flex: none;
  order: 3;
  flex-grow: 0;
  z-index: 3;
`;

export const ModalTitle = styled.div`

  font-family: "eliotpro";
  width:100%
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 49px;
  text-align: center;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const CardOneSubtitle = styled.h3`
  width: 100%;
  height: 27px;
  margin-bottom: 7.5rem;
  /* Body text bold */
  text-align: left;
  font-family: "eliotpro";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Extras/White */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ContainerSection = styled.div`
  display: flex;
  flex-direction: ${(props) => props.fdir || "column"};
  width: ${(props) => props.w || "100%"};
  height: ${(props) => props.h || " "};
  min-height: ${(props) => props.mh || " "};
  margin-left: ${(props) => props.left || " "};
  margin: ${(props) => props.m || " "};
  padding: ${(props) => props.pad || " "};
  align-items: center;
  /* justify-content:center ; */
  background: ${(props) => props.bgck || ""};
  opacity: ${(props) => props.op || " "};
  @media (max-width: 900px) {
    height: ${(props) => props.h900 || "unset "};
    width: ${(props) => props.wMob || "100%"};
  }
  @media (max-width: 700px) {
    min-height: ${(props) => props.minh700 || "  "};
  }
  @media (max-width: 500px) {
    min-height: ${(props) => props.minh500 || "  "};
  }
`;

export const ContainerItems = styled.div`
  display: ${(props) => props.dis || " flex "};
  flex-direction: ${(props) => props.fdir || " "};
  flex-wrap: ${(props) => props.fw || " "};
  width: ${(props) => props.w || "100%"};
  height: ${(props) => props.h || " "};
  margin: ${(props) => props.m || " "};
  padding: ${(props) => props.pad || " "};
  margin-left: ${(props) => props.left || " "};
  background: ${(props) => props.bgck || ""};
  opacity: ${(props) => props.op || " "};
  align-items: ${(props) => props.ai || "center"};
  align-content: ${(props) => props.alc || " "};
  justify-content: ${(props) => props.jc || "center"};
  max-width: ${(props) => props.mw || "81rem"};
  border: ${(props) => props.b || " "};
  border-radius: ${(props) => props.br || " "};
  gap: ${(props) => props.gap || " "};
  @media (max-width: 1300px) {
    padding: ${(props) => props.pad1300 || " "};
  }
  @media (max-width: 900px) {
    flex-direction: ${(props) => props.disMob || " "};
    border: ${(props) => props.b900 || " "};
  }

  @media (max-width: 800px) {
    padding: ${(props) => props.pad800 || "unset"};
    /* font-size:30px */
  }
`;

export const ItemWrapper = styled.div`
  display: ${(props) => props.dis || " flex "};
  width: ${(props) => props.w || "100%"};
  justify-content: ${(props) => props.jc || "center"};
  padding: ${(props) => props.pad || " "};
  flex-wrap: ${(props) => props.wrap || " "};
  border: ${(props) => props.b || " "};
  border-radius: ${(props) => props.br || " "};
  height: 100%;
  min-height: ${(props) => props.minh || " "};
  background-color: ${(props) => props.bgcolor || " "};
  @media (max-width: 1250px) {
    padding: ${(props) => props.pad1250 || " "};
  }
  @media (max-width: 900px) {
    display: ${(props) => props.dis900 || " flex "};
    padding: ${(props) => props.pad1000 || " "};
  }

  @media (max-width: 800px) {
    display: ${(props) => props.dis800 || " "};
    width: ${(props) => props.w800 || "100%"};
    padding: ${(props) => props.pad800 || "unset"};
  }
`;

export const ItemWrapperMob = styled.div`
  display: ${(props) => props.dis || " flex "};
  width: ${(props) => props.w || "100%"};
  justify-content: ${(props) => props.jc || "center"};
  padding: ${(props) => props.pad || " "};

  @media (max-width: 800px) {
    display: ${(props) => props.dis800 || " flex "};
  }
`;

export const ItemSection = styled.div`
  display: ${(props) => props.dis || " flex "};
  flex-direction: ${(props) => props.flexD || "column"};
  flex-wrap: ${(props) => props.wrap || " "};
  width: ${(props) => props.w || " "};
  max-width: ${(props) => props.mw || " "};
  height: ${(props) => props.h || " "};
  margin-left: ${(props) => props.left || " "};
  background: ${(props) => props.bgck || ""};
  opacity: ${(props) => props.op || " "};
  margin: ${(props) => props.m || " "};
  padding: ${(props) => props.pad || " "};
  justify-content: ${(props) => props.jc || " "};
  @media (max-width: 800px) {
    display: ${(props) => props.disMob || "flex"};
    margin: ${(props) => props.mMob || " "};
    width: ${(props) => props.w800 || "80%"};
  }

  @media (max-width: 425px) {
    display: ${(props) => props.disMob || "flex"};
    margin: ${(props) => props.m425Mob || "  "};
    justify-content: center;
    width: ${(props) => props.w425 || "75%"};
  }

  @media (max-width: 390px) {
    display: ${(props) => props.disMob || "flex"};
    margin: ${(props) => props.m425Mob || "  "};
  }
  @media (max-width: 320px) {
    display: ${(props) => props.disMob || "flex"};
    margin: ${(props) => props.m320Mob || "  "};
    padding-left: 0;
    padding-right: 0;
  }
`;

export const MainTitle = styled.h1`
  font-family: "eliotprolight";
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  line-height: 50px;
  color: #ffffff;
  margin-bottom: 37px;

  @media (max-width: 700px) {
    max-width: 328px;
    height: 58px;
    font-size: 30px;
    line-height: 33px;
    color: #ffffff;
    margin-bottom: 50px;
  }
  @media (max-width: 320px) {
    max-width: 290px;
    margin-bottom: 30px;
  }
`;

export const Title = styled.h1`
  font-family: "eliotprobold";
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => props.fontSize || " 26px"};
  line-height: 50px;
  color: #000000;
  margin-bottom: 37px;
  text-align: center;
  @media (max-width: 700px) {
    max-width: 328px;
    height: 58px;
    font-size: 30px;
    line-height: 33px;
    color: #ffffff;
    margin-bottom: 50px;
  }
  @media (max-width: 320px) {
    max-width: 290px;
    margin-bottom: 30px;
  }
`;

export const Label = styled.p`
  width: ${(props) => props.w || " "};
  font-family: "eliotpro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: ${(props) => props.lh || "18px "};
  color: #333333;
  text-align: start;
  margin-top: ${(props) => props.mt || "15px "};
  margin-right: 5px;
  z-index: 0;
  position: relative;
`;
export const InfoSimulador = styled.p`
  font-family: "eliotpro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  margin-top: 1rem;
  line-height: 29px;
  width: 100%;
`;

export const InfoSimuladorMoney = styled.p`
  /* Header 3 */

  font-family: "eliotpro";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;

  /* Primarios/True blue */

  color: #0091da;
`;

export const Labels = styled.label`
  width: ${(props) => props.w || " "};
  font-family: "eliotpro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: ${(props) => props.lh || "18px "};
  color: #333333;
  text-align: start;
  margin-top: ${(props) => props.mt || "15px "};
  margin-right: 5px;
  z-index: 0;
  position: relative;
`;

export const Paragraph14 = styled.p`
  font-family: "eliotprobold";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
  margin-bottom: 37px;
  @media (max-width: 800px) {
    width: ${(props) => props.wMob || " "};
    height: ${(props) => props.hMob || " "};
    margin-bottom: ${(props) => props.mbMob || "35px"};
    font-weight: ${(props) => props.fwMob || "400 "};
  }
  @media (max-width: 320px) {
  }
`;

export const CustomParagraph = styled.div`
  font-family: ${(props) => props.ff || "eliotpro"};
  font-weight: ${(props) => props.fontw || "400"};
  font-size: ${(props) => props.fs || "22px"};
  text-align: ${(props) => props.tal || " "};
  margin: ${(props) => props.m || " "};
  line-height: 325x;
  height: ${(props) => props.h || ""};
  color: ${(props) => props.clr || "#FFFFFF"};
`;

export const CurstomParagraph = styled.div`
  font-family: ${(props) => props.ff || "eliotpro"};
  font-weight: ${(props) => props.fontw || "400"};
  font-size: ${(props) => props.fs || "22px"};
  line-height: 15px;
  color: ${(props) => props.clr || "#FFFFFF"};
`;

export const Paragraph24 = styled.p`
  max-width: ${(props) => props.mw || "450px"};
  height: ${(props) => props.h || " "};
  font-family: "eliotpro";
  font-style: normal;
  font-weight: ${(props) => props.fw || "400"};
  font-size: 24px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: ${(props) => props.mb || "7px"};
  @media (max-width: 800px) {
    width: ${(props) => props.wMob || " "};
    height: ${(props) => props.hMob || " "};
    margin-bottom: ${(props) => props.mbMob || "15px"};
    font-weight: ${(props) => props.fwMob || " "};
  }
  @media (max-width: 320px) {
    margin-bottom: ${(props) => props.mb320Mob || "15px "};
  }
`;

export const ItemRow = styled.div`
  display: ${(props) => props.dis || " flex "};
  flex-wrap: ${(props) => props.fw || " wrap "};
  width: ${(props) => props.w || "100%"};
  align-items: center;
  height: ${(props) => props.h || " "};
  align-content: ${(props) => props.ac || " center "};
  justify-content: ${(props) => props.jc || "center"};
  padding: ${(props) => props.pad || " "};
  margin: ${(props) => props.m || " "};
  font-family: ${(props) => props.ff || "eliotpro"};
  font-weight: ${(props) => props.fw || " "};
  text-align: ${(props) => props.txal || " "};
  @media (max-width: 900px) {
    display: ${(props) => props.dis900 || " flex "};
    width: ${(props) => props.w900 || " "};
    padding: ${(props) => props.pad900 || "unset"};
    margin: ${(props) => props.m900 || " "};
  }

  @media (max-width: 550px) {
    display: ${(props) => props.dis900 || " flex "};
    width: ${(props) => props.w900 || " "};
    padding: ${(props) => props.pad900 || "unset"};
    margin: ${(props) => props.m900 || " "};
  }
`;

export const ItemCol = styled.div`
  display: ${(props) => props.dis || " block "};
  flex-wrap: ${(props) => props.wrap || " wrap "};
  width: ${(props) => props.w || " "};
  max-width: ${(props) => props.mw || " "};
  min-height: ${(props) => props.minh || " "};
  align-items: center;
  align-content: ${(props) => props.ac900 || " center "};
  justify-content: ${(props) => props.jc || "center"};
  padding: ${(props) => props.pad || " "};
  margin: ${(props) => props.m || " 0 3px "};
  background-color: ${(props) => props.bgcolor || " "};
  font-family: ${(props) => props.ff || "eliotpro"};
  @media (max-width: 900px) {
    display: ${(props) => props.dis900 || " "};
    width: ${(props) => props.w900 || " "};
    padding: ${(props) => props.pad900 || "unset"};
    margin: ${(props) => props.m900 || " "};
  }

  @media (max-width: 550px) {
    width: ${(props) => props.w550 || ""};
    min-height: ${(props) => props.minh550 || " "};
  }
`;

export const ContactInfoBox = styled.div`
  display: ${(props) => props.dis || " flex "};
  flex-wrap: ${(props) => props.wrap || " wrap "};
  width: ${(props) => props.w || " "};
  min-width: ${(props) => props.mw || "327px"};
  min-height: ${(props) => props.minh || "102px "};
  align-items: center;
  align-content: ${(props) => props.ac900 || " center "};
  justify-content: ${(props) => props.jc || "center"};
  padding: ${(props) => props.pad || " "};
  margin: ${(props) => props.m || " 0 3px "};
  background-color: ${(props) => props.bgcolor || "#081c2de3"};
  background: ${(props) => props.bg || ""};
  border-radius: 25px;
  color: #fff;
  border: 1px solid #0076cf;
  font-family: ${(props) => props.ff || "eliotprobold"};
  font-weight: 700;
  font-size: 22px;
  transform: ${(props) => props.trans || "translateY(50px)"};
`;

export const InnerInfoBox = styled.div`
  display: ${(props) => props.dis || " block "};
  flex-wrap: ${(props) => props.wrap || " wrap "};
  width: ${(props) => props.w || "100%"};
  align-items: center;
  align-content: ${(props) => props.ac900 || " center "};
  justify-content: ${(props) => props.jc || "center"};
  margin: ${(props) => props.m || "auto"};
  text-align: center;
`;

export const Button = styled.button`
  width: ${(props) => props.w || "181px"};
  height: ${(props) => props.h || "48px"};
  background: ${(props) => props.bgck || "#0061A0"};
  border-radius: 40px;
  color: ${(props) => props.clr || "#FFFFFF"};
  font-family: ${(props) => props.ff || "eliotpro"};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  box-shadow: none;
  margin-top: ${(props) => props.mt || ""};
  margin: ${(props) => props.m || ""};
  border: ${(props) => props.bder || "none"};
  margin-left: ${(props) => props.ml || ""};
  transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.HoverbackgroundColor || "#004887"};
    color: ${(props) => props.Color || "#FFFFFF"};
  }
  @media (max-width: 800px) {
    width: ${(props) => props.wMob || ""};
    height: ${(props) => props.hMob || " "};
    margin: ${(props) => props.mMob || ""};
    margin-bottom: ${(props) => props.mbMob || "15px"};
    align-self: ${(props) => props.als || " "};
  }
`;

export const Image = styled.img`
  max-width: ${(props) => props.maxwidth || "100%"};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || " "};
  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.bottom || ""};
  margin-right: ${(props) => props.marginRight || ""};
  margin-left: ${(props) => props.marginLeft || ""};
  position: ${(props) => props.position || "initial"};
  left: ${(props) => props.left || ""};
  top: ${(props) => props.top || ""};
  cursor: ${(props) => props.cursor || " "};
  display: ${(props) => props.displayWeb || " "};
  border: ${(props) => props.border || " "};
  z-index: ${(props) => props.zindex || ""};
  transform: ${(props) => props.transform || ""};
  transform-origin: ${(props) => props.to || ""};
  max-height: ${(props) => props.mheight || " "};
  @media (max-width: 800px) {
    margin-bottom: ${(props) => props.bottomMObile || ""};
    width: ${(props) => props.mwidth || props.width || "100%"};
    left: ${(props) => props.mleft || ""};
    top: ${(props) => props.mtop || ""};
    margin-right: ${(props) => props.marginRightM || ""};
    margin-top: ${(props) => props.marginTopM || ""};
    margin-left: ${(props) => props.marginLeftM || ""};
  }
  @media (max-width: 700px) {
    display: ${(props) => props.displayMobile || " "};
    height: ${(props) => props.heightMobile || " "};
  }
  @media (max-width: 460px) {
    width: ${(props) => props.mwidth || props.width || "100%"};
    height: ${(props) => props.heightMobile || " "};
    display: ${(props) => props.displayMobile || " "};
    margin-top: ${(props) => props.mtMob || " "};
    margin-bottom: ${(props) => props.mbMob || " "};
    margin-left: ${(props) => props.mlMob || " "};
  }
`;

export const Color = styled.div`
  width: ${(props) => props.w || " 10px"};
  height: ${(props) => props.h || " 10px"};
  align-items: center;
  padding: ${(props) => props.pad || " "};
  margin: ${(props) => props.pad || " 10px 5px "};
  background-color: ${(props) => props.bgcolor || " "};
`;

export const TitleSimuladorAfore = styled.p`
  width: 100%;
  height: ${(props) => props.h || "98px"};
  margin-top: ${(props) => props.mt || " "};
  margin: ${(props) => props.m || " "};
  /* Header 2 */

  font-family: ${(props) => props.fontf || "eliotpro"};
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
`;

export const TitlePerfiladorAfore = styled.p`
  width: 100%;
  height: ${(props) => props.h || " "};
  margin: ${(props) => props.m || " "};
  font-family: ${(props) => props.fontf || "eliotpro"};
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
`;

export const CardAnimationTwo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 32px;
  gap: 24px;

  width: 508px;
  height: 371px;

  /* Extras/White */

  background: #ffffff;
  box-shadow: -8px 8px 25px rgba(0, 76, 151, 0.2);
  border-radius: 16px;
`;

export const CardCarouselItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 10px;
  margin-left: 80px;
  width: 285.33px;
  height: 174px;
  background: #e3f5fd;
  box-shadow: -8px 8px 25px rgba(0, 76, 151, 0.2);
  border-radius: 16px;
`;

export const TextCarouselTwo = styled.p`
  width: ${(props) => props.w || "  "};
  height: 58px;
  font-family: "eliotprolight";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #ffffff;
  margin: ${(props) => props.m || " 0px 0px 33px 60px "};
  padding: ${(props) => props.pad || " "};
`;
export const TextItem = styled.p`
  width: 237.33px;

  font-family: "eliotprolight";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
`;

export const TextCantidad = styled.p`
  width: 175px;
  font-family: "eliotpro";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #004c97;
`;

export const TextCarousel = styled.p`
  width: 1016px;
  font-family: "eliotprolight";
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  color: #ffffff;
  margin: ${(props) => props.m || " "};
`;

export const TextSmall = styled.p`
  width: 302px;
  height: 76px;
  font-family: "eliotprolight";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;

export const ContainerBoxCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 20px;

  width: 474px;
  height: 124px;

  background: #ffffff;
  box-shadow: -8px 8px 25px rgba(0, 76, 151, 0.2);
  border-radius: 16px 0px 0px 0px;
`;

export const ContainerBoxLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 124px;

  /* Primarios/Principal blue */

  background: #0076cf;
  box-shadow: -8px 8px 25px rgba(0, 76, 151, 0.2);
  border-radius: 16px 0px 0px 0px;

  /* Inside auto layout */
`;

export const FormContainer = styled.div`
  display: flex;
  width: ${(props) => props.w || "508px"};
  flex-direction: column;
  height: ${(props) => props.h || "700px"};
  align-items: ${(props) => props.align_it || ""};
  justify-content: ${(props) => props.just_cont || ""};
  background: ${(props) => props.bg_form || "red"};
  gap: ${(props) => props.gap || ""};
  padding: ${(props) => props.pad || ""};
`;

export const FormLabel = styled.p`
  font-family: "eliotprolight";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin: 0px;
  margin-top: 4px;
`;

export const Inp = styled.input`
  box-sizing: border-box;

  box-sizing: border-box;

  width: 450px;
  height: 48px;
  background: #ffffff;
  border-radius: 4px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const Inpt = styled(TextField)`
  width: ${(props) => props.w || " 100%"};
  height: 48.92px;
  font-weight: 400;
  box-sizing: border-box;
  font-size: 16px;
  font-family: "eliotpro";
  background: #ffffff;
  padding: 8px 0px 18px 8px;
  border: 1px solid #333333;
  border-radius: 4px;
  color: #333333;
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin-bottom: ${(props) => props.mbtn || " "};

  &:focus {
    border: 3px solid ${colores.borde};
    outline: none;
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4);
  }

  .MuiInput-underline:before {
    border-bottom: none;
  }
  && .MuiInput-underline:hover:before {
    border-bottom: none;
  }
  /* focused */
  .MuiInput-underline:after {
    border-bottom: none;
  }
`;
