import styled from "styled-components";

export const FooterContainerNav = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.color || " "};
  background-color: ${(props) => props.backcolor || "#E8E8E8"};
  padding-top: ${(props) => props.paddingt || "40px"};
  bottom: 0 !important;
  width: 100%;
  text-align: center;
  height: 400px;
  display: ${(props) => props.displayW || " "};

  @media (max-width: 700px) {
    display: ${(props) => props.displayM || " "};
  }
`;

export const ContainerLogo = styled.div`
  width: 31%;
  margin-left: 75px;
`;

export const FooterContainerItemsNav = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${(props) => props.padding || " "};
  padding-top: ${(props) => props.paddingt || ""};
  padding-bottom: ${(props) => props.paddingMobile || " "};
  margin: ${(props) => props.m || "0px 200px 0px 200px"};
  color: ${(props) => props.color || "black"};
  background-color: ${(props) => props.backcolor || ""};
  height: 350px;
  @media (max-width: 700px) {
    display: ${(props) => props.displayM || " "};
    height: 505px;
  }
  @media (min-width: 480px) {
    display: flex;
    padding-top: ${(props) => props.paddingWeb || "7px"};
    padding-bottom: ${(props) => props.paddingBWeb || " "};
  }
`;

// eslint-disable-next-line camelcase

// eslint-disable-next-line camelcase
export const FooterVerticalsA = styled.a`
  margin: 0;
  text-align: left;
  width: ${(props) => props.width || "100%"};
  margin-left: ${(props) => props.left || " "};
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  cursor: ${(props) => props.cursor || "pointer"};
  font-size: ${(props) => props.size || "16px"};
  line-height: ${(props) => props.line || "255.5%;"};
  color: #061d80;
  /* or 41px */
  &:hover {
    color: #061d80;
  }
  letter-spacing: -0.3px;
  display: ${(props) => props.displayD || "block"};
  @media (max-width: 700px) {
    display: ${(props) => props.displayM || "block"};
  }
`;

// eslint-disable-next-line camelcase
export const FooterDiv3 = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  @media (min-width: 480px) {
    width: 100%;
    padding-left: ${(props) => props.pl || " "};
  }
  @media (max-width: 700px) {
    display: ${(props) => props.displaymobile || "none"};
  }
`;

// eslint-disable-next-line camelcase
export const FooterDiv1 = styled.div`
  width: auto;
  @media (min-width: 480px) {
    width: ${(props) => props.width || "50%"};
    text-aling: left;
    display: none;
  }
  @media (max-width: 700px) {
    display: ${(props) => props.displaymobile || "block"};
  }
`;
// eslint-disable-next-line camelcase
export const FooterDiv2 = styled.div`
  width: auto;
  margin-top: ${(props) => props.mtop || " "};
  @media (min-width: 480px) {
    width: ${(props) => props.width || "50%"};
    margin-top: ${(props) => props.mtop || " "};
    display: none;
  }
`;

export const FooterVerticals = styled.a`
  margin: 0;
  text-align: left;
  width: ${(props) => props.width || "100%"};
  margin-left: ${(props) => props.left || " "};
  font-family: "eliotpro";
  font-style: normal;
  font-weight: bold;
  cursor: ${(props) => props.cursor || "pointer"};
  font-size: ${(props) => props.size || "16px"};
  line-height: ${(props) => props.line || "255.5%;"};
  color: black;
  /* or 41px */
  text-decoration: none;
  &:hover {
    color: black;
    text-decoration: underline;
  }
  letter-spacing: -0.3px;
  display: ${(props) => props.displayD || "block"};
  @media (max-width: 700px) {
    display: ${(props) => props.displayM || "block"};
  }
`;

export const FooterSubTitle = styled.h2`
  font-family: "eliotpro";
  margin: 0;
  text-align: left;
  width: ${(props) => props.width || "100%"};
  margin-left: ${(props) => props.left || " "};
  font-style: normal;
  font-weight: bold;

  font-size: ${(props) => props.size || "16px"};
  line-height: ${(props) => props.line || "255.5%;"};
  color: black;
  /* or 41px */

  letter-spacing: -0.3px;
  display: ${(props) => props.displayD || "block"};
  @media (max-width: 700px) {
    display: ${(props) => props.displayM || "block"};
  }
`;

export const ContainerFooterLinks = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 0px 20px 110px;
`;

export const FooterA = styled.a`
  margin: 0;

  width: ${(props) => props.width || "100%"};
  margin-left: ${(props) => props.left || " "};
  font-family: "eliotpro";
  font-style: normal;
  font-weight: bold;
  cursor: ${(props) => props.cursor || "pointer"};
  font-size: ${(props) => props.size || "16px"};
  line-height: ${(props) => props.line || "255.5%;"};
  color: #0091da;
  text-decoration: none;
  /* or 41px */
  &:hover {
    color: #0091da;
    text-decoration: none;
  }
  letter-spacing: -0.3px;
  display: ${(props) => props.displayD || "block"};
  @media (max-width: 700px) {
    display: ${(props) => props.displayM || "block"};
  }
`;

export const ParfF = styled.p`
  font-size: ${(props) => props.mfonts || " 11px"};
  /*background-color: #fff;*/
  color: ${(props) => props.color || "black"};
  margin-left: ${(props) => props.ml || " "};
  border: none;
`;
