import "./../../assets/styles/flipcard.css";
import { useState } from "react";
import cn from "classnames";
import { ItemRow } from "../../assets/styles/StylesGeneric.styled";

function FlipCard({ card }) {
  const [showBack, setShowBack] = useState(false);

  function handleClick() {
    if (card.variant === "click") {
      setShowBack(!showBack);
    }
  }
  return (
    <div className="flip-card" onClick={handleClick}>
      <div
        className={cn("flip-card-inner", {
          showBack,
          "hover-trigger": card.variant === "hover",
        })}
      >
        <div className="card front flip-card-front">
          <div className="card-body d-flex justify-content-center align-items-center">
            <ItemRow>
              <img
                className="card-text fs-1 fw-bold"
                src={card.src}
                alt=""
              ></img>
            </ItemRow>
            <ItemRow>
              <p className="card-text-front  fw-bold">{card.frontText}</p>
            </ItemRow>
            <ItemRow>
              <img
                className="card-text fs-1 fw-bold"
                src={card.icon}
                alt=""
              ></img>
            </ItemRow>
          </div>
        </div>
        <div className="card back flip-card-back">
          <div className="card-body-back d-flex justify-content-center ">
            <ItemRow>
              <p className="card-title-back  ">{card.backTitle}</p>
            </ItemRow>
            <ItemRow>
              <div className="card-text-back  ">{card.backText1}</div>
            </ItemRow>
            <ItemRow>
              <div className="card-text-back  ">{card.backText2}</div>
            </ItemRow>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlipCard;
