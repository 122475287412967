import React from "react";
import Buro from "./../../assets/img/Buro.png";
import FooterLinks from "./FooterLinks";
import { ParfF } from "../../assets/styles/Footer.styled";

const FooterBuroSection = () => {
  return (
    <div
      style={{
        background: "#f5f5f5",
        width: "100%",
        height: "500px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", width: "55%" }}>
        <FooterLinks />

        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "15px" }}>
            <img src={Buro} alt="Buroimg" width="100px" />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ParfF>
              El Buró de Entidades Financieras es una herramienta de consulta y
              difusión con la que podrás conocer los productos que ofrecen las
              entidades financieras, sus comisiones y tasas, las reclamaciones
              de los usuarios, las prácticas no sanas en que incurren, las
              sanciones administrativas que les han impuesto, las cláusulas
              abusivas de sus contratos y otra información que resulte relevante
              para informarte sobre su desempeño.
            </ParfF>
            <ParfF mt="10px">
              A través del Buró de Entidades Financieras podrás comparar y
              evaluar a las entidades financieras, sus productos y servicios y
              tendrás mayores elementos para elegir lo que más te convenga. Esta
              información te será útil para elegir un producto financiero y
              también para conocer y usar mejor los que ya tienes.
            </ParfF>
          </div>
        </div>
        <ParfF mt="10px">
          El Buró de Entidades Financieras contiene información de PRINCIPAL
          FONDOS DE INVERSIÓN, S.A. DE C.V., OPERADORA DE FONDOS DE INVERSIÓN,
          PRINCIPAL GRUPO FINANCIERO sobre nuestro desempeño frente a los
          Usuarios, por la prestación de productos y servicios. Te invitamos a
          consultarlo en la página del Buró de Entidades Financieras.
          https://www.buro.gob.mx/
        </ParfF>

        <ParfF mt="15px" mfonts="16px" ml="270px">
          2022 Principal México, Todos los derechos reservados
        </ParfF>
      </div>
    </div>
  );
};

export default FooterBuroSection;
