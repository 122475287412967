/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

// import PropTypes from 'prop-types'
import {
  ContainerSection,
  TitlePerfiladorAfore,
  ContainerItems,
  Button,
  ItemCol,
  ItemRow,
  CustomParagraph,
  Title,
  ItemWrapper,
  ItemSection,
  BoxModal,
  ModalTitle,

} from "../assets/styles/StylesGeneric.styled";
import Contacto from "../components/common/Contacto";
import ComponenteInput from "../components/common/Input/Input";
import { makeStyles, Modal } from "@material-ui/core";
import { Formulario } from "../components/common/Input/elementos/Formularios";
import ScreenIcon from "../assets/icons/ScreenIcon.svg"
import closeIcon from "../assets/icons/closeIcon.svg"
import likeIcon from "../assets/icons/likeIcon.svg"

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      padding: "16px 18px"
    },
  }));

function PerfiladorContacto() {

  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  


  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  function handleSubmit() {
    setOpen(true)
  }
  function handleClose() {
    setOpen(false)
  }
  const classes = useStyles();
  const ModalMessage = () => (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <BoxModal style={modalStyle} className={classes.paper}>
        <ItemRow w="100%" jc="flex-end"><img src={closeIcon} alt="" onClick={handleClose}/></ItemRow>
        <ItemRow w="100%"><img src={likeIcon} alt="" /></ItemRow>
        <ItemRow w="100%"><ModalTitle>¡Excelente!</ModalTitle></ItemRow>
        
        <ItemRow w="100%">
        <CustomParagraph fs="18px" clr="#333333">
        Cada vez estás más cerca de realizar tu cambio de Afore a Principal.
        </CustomParagraph>
        </ItemRow>
        <ItemRow w="100%">
        <CustomParagraph fs="18px" clr="#333333">
        Ya tenemos tus datos y serás contactado por un asesor lo antes posible.
        </CustomParagraph>
        </ItemRow>
        <ItemRow w="100%">
        <Button
            type="button"
            onClick={handleClose}
            als="center"
            mb="1rem 0rem auto"
          >
            Terminar
          </Button>
          </ItemRow>
      </BoxModal>
    </Modal>
  );

  const [nombre, setNombre] = useState({ campo: "", valido: null });
  const [apellidoPaterno, setApellidoPaterno] = useState({ campo: "", valido: null });
  const [apellidoMaterno, setApellidoMaterno] = useState({ campo: "", valido: null });
  const [telefono, setTelefono] = useState({ campo: "", valido: null });
  const [correo, setCorreo] = useState({ campo: "", valido: null });
  const [curp, setCurp] = useState({ campo: "", valido: null });

  const expresiones = {
    // usuario: /^[a-zA-Z0-9_-]{4,16}$/, // Letras, numeros, guion y guion_bajo		
    nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.		
    // password: /^.{4,12}$/, // 4 a 12 digitos.		
    correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    telefono: /^\d{7,14}$/, // 7 a 14 numeros.	
    curp: /^.{18,18}$/
  }


  return (
    <>
      <ItemWrapper dis="block" bgcolor="#F0FBFE">
        <ContainerSection bgck="#F0FBFE" w="51rem" m="auto" pad="60px 0">
          <img src={ScreenIcon} alt="" />
          <TitlePerfiladorAfore m="3rem 0"  fontf="eliotprolight">
          Existe una baja probabilidad que tengas <br/> expediente digital.

          </TitlePerfiladorAfore>
          <ItemRow>
            <CustomParagraph clr="#333333" fs="18px" tal="center">
            Un expediente digital es un registro del trabajador en el sistema de ahorro para el retiro, sirve para realizar de forma más ágil los trámites relacionados con tu cuenta de Afore. 
            </CustomParagraph>

            <CustomParagraph clr="#333333" fs="28px" ff="eliotprobold" tal="center" m="15px 0 10px">
            Para obtener tu expediente necesitas ayuda de un asesor.
            </CustomParagraph>

            <CustomParagraph clr="#333333" fs="18px" tal="center" m="10px 0">
            Registra los siguientes datos para ser contactado por un asesor lo antes posible.
            </CustomParagraph>
          </ItemRow>

          <ContainerItems ai="start" pad="0rem 0rem 4rem">
            <ItemSection w="100%" mw="450px" wrap="wrap"  >
              <Formulario style={{ gap: "3rem" }} gtc="1fr" >
                <div>

                  <ComponenteInput
                    estado={nombre}
                    cambiarEstado={setNombre}
                    tipo="text"
                    placeholder=""
                    name="nombre"
                    label="Nombre"
                    expresionRegular={expresiones.nombre}
                    leyendaError="Nombre inválido"
                  />


                  <ComponenteInput
                    estado={apellidoPaterno}
                    cambiarEstado={setApellidoPaterno}
                    tipo="text"
                    placeholder=""
                    label="Apellido Paterno"
                    name="apellido-paterno"
                    leyendaError="Apellido inválido"
                    expresionRegular={expresiones.nombre}
                  />


                  <ComponenteInput
                    id="formatted-numberformat-input"
                    estado={apellidoMaterno}
                    cambiarEstado={setApellidoMaterno}
                    tipo="text"
                    placeholder=""
                    label="Apellido Materno"
                    name="apelido-materno"
                    expresionRegular={expresiones.nombre}
                    leyendaError="Apellido inválido"
                  />

                  <ComponenteInput
                    estado={telefono}
                    cambiarEstado={setTelefono}
                    tipo="text"
                    placeholder=""
                    name="nombre"
                    label="Teléfono Celular"
                    expresionRegular={expresiones.telefono}
                    leyendaError="Teléfono celular inválido"
                  />

                  <ComponenteInput
                    estado={correo}
                    cambiarEstado={setCorreo}
                    tipo="text"
                    placeholder=""
                    name="nombre"
                    label="Correo electrónico"
                    expresionRegular={expresiones.correo}
                    leyendaError="Correo inválido"
                  />

                  <ComponenteInput
                    estado={curp}
                    cambiarEstado={setCurp}
                    tipo="text"
                    placeholder=""
                    name="nombre"
                    label="CURP"
                    expresionRegular={expresiones.curp}
                    leyendaError="CURP invalida"
                    inputProps={{ style: { textTransform: "lowercase" } }}
                  />

                </div>
              </Formulario>
            </ItemSection>
          </ContainerItems>
          <Button
            type="button"
            onClick={handleSubmit}
            als="center"
            mb="1rem 0rem auto"
          >
            Contactarme
          </Button>
         
        </ContainerSection>
      </ItemWrapper>
      <Contacto />
      <ContainerItems bgck="#E3F5FD" br="16px" m="auto 0">
            {open ? <ModalMessage /> : null}
      </ContainerItems>

    </>
  );
}

// LpAfore.propTypes = {}

export default PerfiladorContacto;
