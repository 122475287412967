import React, { useEffect, useState } from "react";
import {
  ContainerItems,
  TextCarousel,
  CardCarouselItem,
  TextItem,
  TextCantidad,
  ContainerBoxCard,
  TextSmall,
  ContainerBoxLeft,
  Button,
  TextCarouselTwo,
  FormContainer,
  BoxStyle,
  BoxTitle,
  BoxDescription,
  CardOneSubtitle,
} from "../../assets/styles/StylesGeneric.styled";
import AnimationBox from "./../components/AnimationBox";
import Rowr from "./../../assets/img/Rowr.svg";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { makeStyles, Modal } from "@material-ui/core";
import ImageCard2 from "../../assets/images/ModalCard2.svg";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ComponenteInput from "../../components/common/Input/Input";
import { Formulario } from "../../components/common/Input/elementos/Formularios";
import { NumberFormatCustom } from "../../utils/formatNumber";
import Monedas from "../../assets/images/moneda.svg";

const SliderAfore = styled(Slider)({
  color: "#52af77",
  height: 8,
  "& .MuiSlider-track": {
    backgroundColor: "#00EFE8",
  },

  "& .MuiSlider-thumb": {
    backgroundColor: "#00EFE8",
    margin: "0px 0px 40px 0px",
  },
  "& .MuiSlider-valueLabel": {
    background: "#00EFE8",
    padding: "8px 16px",
    width: 100,
    height: 40,
    borderRadius: "20px",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    // transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    // "&.MuiSlider-valueLabelOpen": {
    //   transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    // },
    // "& > *": {
    //   transform: "rotate(45deg)",
    // },
    "& .MuiSlider-rail": {
      color: "#00EFE8",
    },
    "& .MuiSlider-mark": {
      backgroundColor: "#00EFE8",
    },
  },
});
const marks = [
  {
    value: 0,
    label: "$0",
  },
  {
    value: 60000,
    label: "$60,000",
  },
];

function valuetext(value) {
  return `${value}$`;
}

if (marks.value === 40) {
  alert("hola mundo ");
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    padding: theme.spacing(2, 4, 3),
  },
}));
// import MonedaBox from "./../../assets/img/MonedaBox.svg";
export const CardOne = () => {
  /*const [input, setInput] = useState("");
  const handleOnChange = (event) => {
    setInput(event.target.value);
  };*/

  function ValueLabelComponent(props) {
    const { children, value } = props;

    return (
      <Tooltip
        open
        placement="top"
        title={Intl.NumberFormat("ES-MX", {
          style: "currency",
          currency: "MXN",
        }).format(value)}
        arrow
      >
        {children}
      </Tooltip>
    );
  }
  return (
    <>
      <ContainerItems fdir="column" m="100px 0 0 0" h=" ">
        <TextCarousel>
          ¿Cuándo tengas 60 años, cuánto dinero te gustaría tener mensualmente?
        </TextCarousel>
        <ContainerItems m="5rem 0rem 0rem" gap="5rem">
          <ContainerItems fdir="column" mw="40rem">
            <CardOneSubtitle>Me gustaría tener mensualmente:</CardOneSubtitle>
            <ContainerItems>
              <SliderAfore
                aria-label="Always visible"
                size="big"
                getAriaValueText={valuetext}
                valueLabelDisplay="on"
                min={0}
                max={60000}
                components={{
                  ValueLabel: ValueLabelComponent,
                }}
                marks={marks}
              />
            </ContainerItems>
            <Button clr="#0061A0" bgck="#fff" mt="2rem">
              Continuar
            </Button>
          </ContainerItems>

          <ContainerItems>
            <AnimationBox />
          </ContainerItems>
        </ContainerItems>
      </ContainerItems>
    </>
  );
};

export const CardTwo = () => {
  const [open, setOpen] = React.useState(true);
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
    }, 5000);
  }, []);

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const ModalMessage = () => (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <BoxStyle style={modalStyle} className={classes.paper}>
        <img src={ImageCard2} alt="" />
        <BoxTitle>¿Sabías que?</BoxTitle>
        <BoxDescription>
          Los Afores son una forma fácil de ahorrar para lograr esos objetivos
          que tienes en mente, lo mejor de todo es que tú decides cuanto ahorrar
          y en cuanto tiempo lo puedes ahorrar, sin plazos forzosos, sin
          perdidas.
        </BoxDescription>
      </BoxStyle>
    </Modal>
  );

  const [nombre, setNombre] = useState({ campo: "", valido: null });
  const [telefono, setTelefono] = useState({ campo: "", valido: null });
  const [correo, setCorreo] = useState({ campo: "", valido: null });
  const [curp, setCurp] = useState({ campo: "", valido: null });

  const expresiones = {
    // usuario: /^[a-zA-Z0-9_-]{4,16}$/, // Letras, numeros, guion y guion_bajo
    nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
    // password: /^.{4,12}$/, // 4 a 12 digitos.
    correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    telefono: /^\d{7,14}$/, // 7 a 14 numeros.
    curp: /^.{18,18}$/,
  };

  const [edad, setEdad] = useState({
    campo: 0,
    valido: "",
  });

  const [ingresoMensual, setIngresoMensual] = useState({
    campo: 0,
    valido: "",
  });

  const validarEdad = (estado, cambiarEstado, min, max) => {
    if (estado.campo > min && estado.campo < max) {
      cambiarEstado({ ...estado, valido: "true" });
    } else {
      cambiarEstado({ ...estado, valido: "false" });
    }
  };

  const validarDineroMin = (estado, cambiarEstado, min, max) => {
    console.log(parseInt(estado.campo));
    if (parseInt(estado.campo) >= min && estado.campo.length < max) {
      cambiarEstado({ ...estado, valido: "true" });
    } else {
      cambiarEstado({ ...estado, valido: "false" });
    }
  };
  return (
    <>
      <ContainerItems fdir="column" m="100px 0 0 0" h=" ">
        <TextCarousel m="0px 0px 0px 0px">
          Ayúdame con lo siguiente
        </TextCarousel>

        <ContainerItems mw="65rem">
          <ContainerItems w="50%" m="35px 0px 0px 0px" fdir="column">
            <TextCarouselTwo m=" " pad="0 15px">
              Te pediremos algunos datos para apoyarte con más información sobre
              Afores.
            </TextCarouselTwo>

            <FormContainer
              m="34px 30px 0px 30px"
              bg_form="none"
              w="26rem"
              h=" "
            >
              <Formulario style={{ gap: "3rem" }} gtc="1fr">
                <div>
                  <ComponenteInput
                    estado={nombre}
                    cambiarEstado={setNombre}
                    tipo="text"
                    placeholder=""
                    name="nombre"
                    label="Nombre"
                    padLabel="5px 0"
                    hLabel=" "
                    clrLabel="#fff"
                    fontwLabel="400"
                    expresionRegular={expresiones.nombre}
                    leyendaError="Nombre invalidó"
                    border="none"
                  />

                  <ComponenteInput
                    cambiarEstado={setEdad}
                    estado={edad}
                    type="number"
                    label="Edad"
                    padLabel="5px 0"
                    hLabel=" "
                    clrLabel="#fff"
                    fontwLabel="400"
                    placeholder=""
                    border="none"
                    name="edad"
                    funcion={() => validarEdad(edad, setEdad, 18, 60)}
                    leyendaError="Requiere una edad mayor a 18 años y menor a 60 años"
                  />

                  <ComponenteInput
                    estado={telefono}
                    cambiarEstado={setTelefono}
                    tipo="text"
                    placeholder=""
                    name="nombre"
                    label="Teléfono"
                    padLabel="5px 0"
                    hLabel=" "
                    clrLabel="#fff"
                    fontwLabel="400"
                    border="none"
                    expresionRegular={expresiones.telefono}
                    leyendaError="Teléfono celular invalidó"
                  />

                  <ComponenteInput
                    estado={correo}
                    cambiarEstado={setCorreo}
                    tipo="text"
                    placeholder=""
                    name="nombre"
                    label="E-mail"
                    padLabel="5px 0"
                    hLabel=" "
                    border="none"
                    clrLabel="#fff"
                    fontwLabel="400"
                    expresionRegular={expresiones.correo}
                    leyendaError="Correo invalidó"
                  />

                  <ComponenteInput
                    estado={curp}
                    cambiarEstado={setCurp}
                    tipo="text"
                    placeholder=""
                    name="nombre"
                    label="CURP"
                    padLabel="5px 0"
                    border="none"
                    hLabel=" "
                    clrLabel="#fff"
                    fontwLabel="400"
                    expresionRegular={expresiones.curp}
                    leyendaError="CURP invalida"
                    inputProps={{ style: { textTransform: "lowercase" } }}
                  />
                  <ComponenteInput
                    cambiarEstado={setIngresoMensual}
                    estado={ingresoMensual}
                    placeholder=""
                    label="Ingreso mensual"
                    border="none"
                    padLabel="5px 0"
                    hLabel=" "
                    clrLabel="#fff"
                    fontwLabel="400"
                    funcion={() =>
                      validarDineroMin(
                        ingresoMensual,
                        setIngresoMensual,
                        1000,
                        9
                      )
                    }
                    leyendaError="Requiere un mínimo de $1,000"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    name="ingreso-mensual"
                  />
                </div>
              </Formulario>
            </FormContainer>
          </ContainerItems>

          <ContainerItems w="47%" h="370px" bgck="#E3F5FD" br="16px" m="auto 0">
            {open ? <ModalMessage /> : null}
          </ContainerItems>
        </ContainerItems>
      </ContainerItems>
    </>
  );
};

export const CardThree = () => {
  const navigate = useNavigate();
  return (
    <>
      <ContainerItems fdir="column" m="100px 0 0 0" h=" ">
        <TextCarousel m="0px 0px 33px 0px">¡Excelente!</TextCarousel>
        <TextCarouselTwo w="1016px">
          De esta forma puedes lograr tus objetivos de ahorro para tu vejez ,
          permítenos darte más información y llevarte de la mano.
        </TextCarouselTwo>

        <ContainerItems m="0px 0px 99px 0px" fdir="row">
          <CardCarouselItem>
            <TextItem>¡Me gustaría tener mensualmente!</TextItem>
            <TextCantidad>$25,000</TextCantidad>
          </CardCarouselItem>
          <CardCarouselItem>
            <TextItem> Tendrías que aportar mensualmente: </TextItem>
            <TextCantidad>$5,000 </TextCantidad>
          </CardCarouselItem>
          <CardCarouselItem>
            <TextItem> ¡Podrías tener en tu Afore a los 60 años! </TextItem>
            <TextCantidad>$2,000,000 </TextCantidad>
          </CardCarouselItem>
        </ContainerItems>

        <ContainerItems gap="2rem">
          <ContainerBoxCard>
            <ContainerBoxLeft>
              <img src={Monedas} alt="Moneda" width="55rem" height="55rem" />
            </ContainerBoxLeft>
            <TextSmall>
              Ya tienes en mente cuanto te gustaría tener el día que te retires.{" "}
              <br />
              Prueba nuestro simulador para saber que <br /> tan cerca te
              encuentras de ese objetivo.
            </TextSmall>

            <img src={Rowr} alt="row" />
          </ContainerBoxCard>
          <Button
            bgck="#FFFFFF"
            clr="#0061A0"
            onClick={(e) => {
              e.preventDefault();
              navigate("/simulador");
            }}
          >
            Ir al simulador
          </Button>
        </ContainerItems>
      </ContainerItems>
    </>
  );
};
