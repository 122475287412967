import Api from "../api";

const BUSCARAFORE = 'buscar-afore';
const SIMULADORAFORE = 'simulador-afore'
const MINISIMULADORAFORE= 'miniSimulador-afore'

export default {
  buscarAfore(data){
    return Api.post(BUSCARAFORE, data);
  },
  simuladorAfore(data){
    return Api.post(SIMULADORAFORE, data);
  },
  miniSimuladorAfore(data){
    return Api.post(MINISIMULADORAFORE, data);
  }
}